import React from "react";
import Seo from "../components/Seo";
import IconInstagram from "../images/IconInstagram";

import logo from "../images/restoration-workshop.svg"

const ErrorPage = () => {
    return (
        <>
        <Seo title="404 Page Not Found" description="Oops, sorry page cannot be found" />
        <div className="w-full h-full min-h-screen" style={{ backgroundColor: "#E2DDDD" }}>
          <div className="container mx-auto h-full min-h-screen">
            <div className="h-full min-h-screen flex flex-col items-center justify-center mx-6 overflow-hidden py-6">
              <img src={logo} alt="Restoration Workshop" className=" w-auto max-w-3xl" />
              <h1 className="text-center font-semibold">Oops, sorry page cannot be found</h1>
              <p className="text-center">Checkout our Instagram....</p>
              <div className="flex justify-center">
                <a href="https://www.instagram.com/restorationworkshop.co.uk/" target="_blank" rel="noreferrer" className="text-stone-800">
                  <IconInstagram width={50} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
};

export default ErrorPage;